
import { PremiumClient, StripeClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import PremiumHeader from '../../components/premium/premiumHeader.vue';
import PremiumService from '../../components/premium/premiumService.vue';
import StripeSubscription from '@/components/stripe/stripeSubscription.vue';
import * as OM from '@/Model';

@Options({
    components: {
        PremiumHeader,
        PremiumService,
        StripeSubscription
    }
})
export default class Premium extends Vue {

    @Prop() callback: any;
    @Prop() cordovaBuy: any;
    @Prop() premium : OM.PremiumVM;
    @Prop() priceText: string;
    text: string = "";

    isCordova: boolean = false;
    step: number = 1;
    stripePriceId: string = "";

    created() {
        this.text = "Procedendo, <br /> accetti i nostri <a target='_blank' href='{{termslink}}'>Termini e Condizione</a>. <br/> Una volta confermato il pagamento il tuo abbonamento verrà rinnovato in automatico per la stessa durata e allo stesso costo, finchè non lo annullerai dalle impostazioni del tuo store.";
        if(window.cordova)
            this.isCordova = true;
    }

    goToPayment() {
        if(this.isCordova) {
            this.cordovaBuy();
        }
        else {
            // stripe
            StripeClient.getProductPriceId(this.premium.stripe.subscriptionId)
            .then(x => {
                this.step = this.step + 1;
                this.stripePriceId = x;
            })

        }
    }

}
