import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_premium_buy = _resolveComponent("premium-buy")
  const _component_premium_active = _resolveComponent("premium-active")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_header_feven, { white: false }),
    _createVNode("div", null, [
      (!_ctx.model && _ctx.loaded)
        ? (_openBlock(), _createBlock(_component_premium_buy, {
            key: 0,
            refresh: _ctx.refresh
          }, null, 8, ["refresh"]))
        : (_ctx.model && _ctx.loaded)
          ? (_openBlock(), _createBlock(_component_premium_active, {
              key: 1,
              premiumModel: _ctx.model,
              refresh: _ctx.refresh
            }, null, 8, ["premiumModel", "refresh"]))
          : _createCommentVNode("", true)
    ])
  ], 64))
}