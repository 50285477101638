
import { PremiumClient, StripeClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { debugAnalytics, purchaseAndroid, purchaseIos, stripeKey } from '@/config';
import { PurchasesOffering, PurchasesOfferings, PurchasesPackage, CustomerInfo } from 'cordova-plugin-purchases';
import { CommonServices } from '@/services/CommonServices';
import PremiumHeader from './premiumHeader.vue';
import PremiumService from './premiumService.vue';
import PremiumModal from '../../modals/premium/premiumModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { PurchasesServices } from '@/services/PurchasesServices';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
        PremiumHeader,
        PremiumService
    }
})
export default class PremiumBuy extends Vue {

    @Prop() refresh : () => void;

    list: OM.PremiumVM[] = [];
    selectedPremiumIdentifier: string = "";
    loaded: boolean = false;

    priceText: string = "";

    //cordova obj
    offeringProductIds : string[] = [];
    allPackage: {
        [key: string]: PurchasesOffering;
    } = null;

    selectedPackage: PurchasesOffering = null;

    async created() {
        if(!window.cordova) {
            this.getStripeProduct();
        } else {
            this.getCordovaProduct();
        }
    }

    getStripeProduct(){
        StripeClient.getAllAvailableProducts()
        .then(x => {
            var stripeProducts = x;
            var ids = stripeProducts.map(y => y.id);
            var request: OM.ListStringContainerVM = { list: ids };

            PremiumClient.getStripePremium(request)
            .then(x => {
                this.list = x;

                if(this.list.length > 0) {
                    var selected = this.list[0];
                    this.selectedPremiumIdentifier = selected.identifier;
                    this.priceText = stripeProducts.find(x => x.id == selected.stripe.subscriptionId).priceText;
                }
            }).finally( () => {
                this.loaded = true;
            });
        })
    }

    getCordovaProduct(){
        CommonServices.showSpinner();

        //l'oggetto Purchases viene creato nel loggeduser_layout
        //Per poter prendere le informazioni del customer ovunque
        if(debugAnalytics)
            Purchases.setDebugLogsEnabled(true);

        this.getOfferings()
        .then( async x => {
            var request: OM.ListStringContainerVM = { list: this.offeringProductIds };

            if (this.isIos){
                this.list = await PremiumClient.getIosPremium(request);
                this.selectedPremiumIdentifier = this.list.find( x => x.iosStore.productId == this.selectedPackage.identifier).identifier;
            }
            else if (this.isAndroid){
                this.list = await PremiumClient.getAndroidPremium(request);
                this.selectedPremiumIdentifier = this.list.find( x => x.androidStore.productId == this.selectedPackage.identifier).identifier;
            }
            
            CommonServices.hideSpinner();
        })
        .finally( () => {
            this.loaded = true;
        });;
    }

    getOfferings() : Promise<void>{
        let prom = new Promise<void>((resolve, reject) => {
            this.offeringProductIds = [];
    
            Purchases.getOfferings(
                (offerings: PurchasesOfferings) => {
                    this.allPackage = offerings.all;

                    var keys = Object.keys(offerings.all);

                    for(var i = 0; i < keys.length; i++){
                        var key = keys[i];

                        if(i == 0){
                            this.selectedPackage = offerings.all[key]
                            this.priceText = this.selectedPackage.availablePackages[0].product.priceString;
                        }
                     
                        this.offeringProductIds.push(offerings.all[key].identifier);
                    }

                    // console.log(offerings);

                    if (offerings.current !== null) {  
                        // Display current offering with offerings.current
                    }

                    resolve();
                },
                error => {
                    reject(error)
                    console.log(error);
                }
            );
        })

        return prom;
    }

    buy(){
        CommonServices.showSpinner();
        var purchasePackage: PurchasesPackage = null;

        purchasePackage = this.selectedPackage.availablePackages[0];

        if(purchasePackage == null){
            console.error("Nessun pacchetto selezionato");
            CommonServices.hideSpinner();
            return;
        }

        Purchases.purchasePackage(purchasePackage, async ({ productIdentifier, customerInfo }) => 
        {
            // console.log(productIdentifier);
            // console.log(customerInfo);
            if (typeof customerInfo.entitlements.active[purchasePackage.product.identifier] !== "undefined") {

                setTimeout(() => {
                    store.state.premiumActiveModalSeen = true;
                    CommonServices.hideSpinner();
                    this.$opModal.closeLast();
                    this.refresh();
                }, 5000);
                // var customerInfoVM = new OM.CustomerInfoVM();
                // customerInfoVM.provider = VM.PremiumPurchasedProvider.Android;

                // if (window.cordova.platformId === 'ios')
                //     customerInfoVM.provider = VM.PremiumPurchasedProvider.Apple;

                // var el = (<CustomerInfo>customerInfo).entitlements.active[purchasePackage.product.identifier];
                // var toAdd: OM.PurchaseListVM = {
                //     expirationDate: el.expirationDate,
                //     isActive: el.isActive, 
                //     latestPurchaseDate: el.latestPurchaseDate,
                //     productId: el.productIdentifier,
                //     subscriptionId: el.identifier,
                //     willRenew: el.willRenew
                // }

                // customerInfoVM.list.push(toAdd);
                // await PremiumClient.checkPremiumPlan(customerInfoVM, false);
                // // Unlock that great "pro" content
            } else {
                CommonServices.hideSpinner();
                this.$opModal.closeLast();
                this.refresh();
            }
            // await PurchasesServices.getCustomerInfoAndUpdate();
        },
        ({error, userCancelled}) => {
            console.log(error)
            console.log(userCancelled)
            CommonServices.hideSpinner();
            // Error making purchase
        }
        );
    }

    get isIos(){
        return window.cordova.platformId === 'ios';
    }
    get isAndroid(){
        return window.cordova.platformId === 'android';
    }

    get selectedPremium(){
        if(!this.selectedPremiumIdentifier)
            return new OM.PremiumVM();

        return this.list.find( x => x.identifier == this.selectedPremiumIdentifier);
    }

    openModal(){
        if(!this.selectedPremium.identifier)
            return;

        this.$opModal.show(PremiumModal, {
            premium: this.selectedPremium,
            cordovaBuy: this.buy,
            priceText: this.priceText,
            callback: () => {
                this.$opModal.closeLast();
                this.refresh();
            }
        })
    }
}
