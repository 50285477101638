import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'
import _imports_1 from '@/assets/img/arrow_left_blue.svg'


const _hoisted_1 = {
  key: 0,
  class: "modal full bg_white"
}
const _hoisted_2 = { class: "modal_header" }
const _hoisted_3 = { class: "modal_body text-center" }
const _hoisted_4 = { class: "flex_centered mt-4" }
const _hoisted_5 = { class: "modal_footer flex_spacebetween_centered" }
const _hoisted_6 = {
  key: 1,
  class: "modal full bg_white"
}
const _hoisted_7 = { class: "modal_header" }
const _hoisted_8 = { class: "modal_body h-auto text-center" }
const _hoisted_9 = { class: "flex_centered mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_feven = _resolveComponent("header-feven")
  const _component_localized_text = _resolveComponent("localized-text")
  const _component_premium_header = _resolveComponent("premium-header")
  const _component_premium_service = _resolveComponent("premium-service")
  const _component_stripe_subscription = _resolveComponent("stripe-subscription")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (_ctx.step == 1)
      ? (_openBlock(), _createBlock("div", _hoisted_1, [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_header_feven, { white: false }, {
              right: _withCtx(() => [
                _createVNode("img", {
                  src: _imports_0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$opModal.closeLast()))
                })
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_3, [
            _createVNode(_component_localized_text, {
              class: "fev_text5 fw_700",
              localizedKey: "app_AttivaPremium",
              text: "Attiva Premium"
            }),
            _createVNode("div", _hoisted_4, [
              _createVNode(_component_premium_header, {
                class: "active",
                premium: _ctx.premium,
                priceText: _ctx.priceText
              }, null, 8, ["premium", "priceText"])
            ]),
            (_ctx.isCordova)
              ? (_openBlock(), _createBlock(_component_localized_text, {
                  key: 0,
                  localizedKey: "app_ProcedendoAccettiINostriTerminiECondizione_PremiumPayment",
                  text: _ctx.text,
                  object: { termslink: _ctx.$localizationService.getTermsAndConditionsLink(), privacylink: _ctx.$localizationService.getPrivacyPolicyLink() },
                  class: "fev_text6 mt-4"
                }, null, 8, ["text", "object"]))
              : _createCommentVNode("", true),
            _createVNode(_component_localized_text, {
              class: "fev_text5 fw_800 mb-2 mt-4",
              localizedKey: "app_InclusoConPremium",
              text: "Incluso con Premium"
            }),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.premium.includedServices, (service) => {
              return (_openBlock(), _createBlock(_component_premium_service, { premiumService: service }, null, 8, ["premiumService"]))
            }), 256))
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode("div", null, [
              _createVNode("span", {
                class: "fev_text5 fw_700",
                innerHTML: _ctx.$localizationService.getTextFromLocalizedValue(_ctx.premium.title)
              }, null, 8, ["innerHTML"]),
              _createVNode(_component_localized_text, {
                class: "fev_text5 fw_700",
                localizedKey: "app_Totale{{total}}",
                text: "Totale: {{total}}",
                object: { total: _ctx.priceText}
              }, null, 8, ["object"])
            ]),
            _createVNode("button", {
              class: "fev_button bg_brand w-50",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToPayment()))
            }, [
              _createVNode(_component_localized_text, {
                class: "fev_text5 fw_800",
                localizedKey: "app_Paga",
                text: "Paga"
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.step == 2)
      ? (_openBlock(), _createBlock("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_header_feven, { white: false }, {
              left: _withCtx(() => [
                _createVNode("img", {
                  src: _imports_1,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.step = 1))
                })
              ]),
              right: _withCtx(() => [
                _createVNode("img", {
                  src: _imports_0,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$opModal.closeLast()))
                })
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_8, [
            _createVNode(_component_localized_text, {
              class: "fev_text5 fw_700 mt-4",
              localizedKey: "app_AttivaPremium",
              text: "Attiva Premium"
            }),
            _createVNode("div", _hoisted_9, [
              _createVNode(_component_premium_header, {
                class: "active",
                premium: _ctx.premium,
                priceText: _ctx.priceText
              }, null, 8, ["premium", "priceText"])
            ]),
            _createVNode(_component_localized_text, {
              localizedKey: "app_ProcedendoAccettiINostriTerminiECondizione_PremiumPayment",
              text: _ctx.text,
              object: { termslink: _ctx.$localizationService.getTermsAndConditionsLink(), privacylink: _ctx.$localizationService.getPrivacyPolicyLink() },
              class: "fev_text6 mt-4"
            }, null, 8, ["text", "object"]),
            _createVNode(_component_stripe_subscription, {
              stripePriceId: _ctx.stripePriceId,
              premiumIdentifier: _ctx.premium.identifier,
              onPaymentCompleted: _cache[5] || (_cache[5] = ($event: any) => (_ctx.callback()))
            }, null, 8, ["stripePriceId", "premiumIdentifier"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}