
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient, StripeClient } from '@/services/Services';
import PremiumService from './premiumService.vue';
import FullHorizontalScroll from '@/components/fullHorizontalScroll.vue';
import CancelSubscriptionModal from '../../modals/premium/cancelSubscriptionModal.vue';
import LocalizedText from '@/components/standard/localizedText.vue';
import * as OM from '@/Model';

@Options({
    components: {
        PremiumService
    }
})
export default class PremiumActive extends Vue {

    @Prop() refresh: any;

    @Prop({
        default: () => new OM.PremiumActiveVM()
    }) premiumModel: OM.PremiumActiveVM;

    endDate: Date = null;
    isCordova: boolean = false;

    created() {
        this.endDate = new Date(this.premiumModel.endDate);
        if(window.cordova)
            this.isCordova = true;
    }

    // stopPremium() {
    //     StripeClient.getCurrentSubscriptionId()
    //     .then(x => {
    //         var model = new OM.SubscriptionCancelationModeVm();
    //         model.id = x;

    //         this.$opModal.show(CancelSubscriptionModal, {
    //             confirm: this.$localizationService.getLocalizedValue("app_AnnullaRinnovo", "Annulla rinnovo"),
    //             deny: this.$localizationService.getLocalizedValue("app_AnnullaAbbonamento", "Annulla abbonamento"),
    //             confirmCb: () => {
    //                 model.cancelAtPeriodEnd = true;
    //                 this.cancelSubscription(model);
    //             },
    //             denyCb: () => {
    //                 model.cancelAtPeriodEnd = false;
    //                 this.cancelSubscription(model);
    //             },
    //         })
    //     })
    // }

    // cancelSubscription(model: OM.SubscriptionCancelationModeVm) {
    //     StripeClient.cancelSubscription(model)
    //     .then(x => {
    //         this.$opModal.closeLast();
    //         this.refresh();
    //     })
    // }
}
