
import { AppUserClient, PremiumClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { CommonServices } from '@/services/CommonServices';
import premiumBuy from '../components/premium/premiumBuy.vue';
import premiumActive from '../components/premium/premiumActive.vue';
import * as OM from '@/Model';
import store from '@/store';
import PremiumBuySuccessModal from '../modals/premium/premiumBuySuccessModal.vue';

@Options({
    components: {
        premiumBuy,
        premiumActive
    }
})
export default class Premium extends Vue {

    affinityIdentifier: string = "";
    model: OM.PremiumActiveVM = null;
    loaded: boolean = false;

    created() {
        this.affinityIdentifier = this.$route.params.affinityIdentifier ? this.$route.params.affinityIdentifier.toString() : "";
        this.init();
    }

    init() {
        this.loaded = false;
        this.model = null;
        PremiumClient.getActive()
        .then(x => {
            this.model = x;
            this.loaded = true;
        })
    }

    refresh(){
        AppUserClient.tokenUpgrade()
        .then(x => {
            CommonServices.setAuthToken(x.token);

            this.$opModal.show(PremiumBuySuccessModal, {
                callback: () => {
                    if(!store.state.afterPremiumAction){
                        this.init();
                        return;
                    }

                    store.state.afterPremiumAction()
                    .then( () => {
                        store.state.afterPremiumAction = null;
                    });
                }
            })
        })
    }
}
