
import { PremiumClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class PremiumHeader extends Vue {

    @Prop() premium: OM.PremiumVM;
    @Prop() priceText: string;

}
