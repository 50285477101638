
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import PremiumHeader from '../../components/premium/premiumHeader.vue';
import PremiumService from '../../components/premium/premiumService.vue';
import StripeSubscription from '@/components/stripe/stripeSubscription.vue';
import * as OM from '@/Model';

@Options({
    components: {
        PremiumHeader,
        PremiumService,
        StripeSubscription
    }
})
export default class PremiumBuySuccessModal extends Vue {

    @Prop() callback: any;

    created() {
    }

    next(){
        this.$opModal.closeLast();
        this.callback();
    }

}
